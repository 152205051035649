import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Group,
  createStyles,
  Select,
  Text,
  Center,
  MultiSelect,
  ActionIcon,
} from "@mantine/core";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { Mail, X } from "tabler-icons-react";
import Loading from "../../components/low-level/loading";
import { useTranslation } from "react-i18next";
import { useMembers } from "../../hooks/members.hook";
import { useEventMembers } from "../../hooks/event-members.hook";
import { useUser } from "../../hooks/user.hook";
import Button from "../../components/low-level/button/button";
import { setLoading } from "../../store/reducers/current-event.reducer";

const EditEventPage: React.FunctionComponent = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation("common");
  const [dataForSelect, setDataForSelect]: any = useState([]);
  const [selectEmail, setSelectEmail]: any = useState([]);
  const [data, setData]: any = useState([]);
  const [deleteMemberItem, setDeleteMemberItem]: any = useState([]);
  const [cancel, setCancel]: any = useState(false);
  const [save, setSave]: any = useState(true);
  const [change, setChange]: any = useState(false);
  const [membersData, setMembersData]: any = useState([]);

  const [error, setError]: any = useState([]);
  var eventId: any = localStorage.getItem("eventId");

  const {
    formState: { errors },
  } = useForm();
  const {
    handlers: membersHandlers,
    currentMember,
    members,
    loading,
  }: any = useMembers();

  const { handlers: eventMembersHandlers, allByEventMembers }: any =
    useEventMembers();
  const { handlers: userHandlers, profile, profiles }: any = useUser();

  const roles = [
    { value: "Admin", label: t("admin") },
    { value: "Interpreter", label: t("interpreter") },
    { value: "User", label: t("user") },
    { value: "Owner", label: t("owner") },
    { value: "Customer", label: t("customer") },
    { value: "SignLanguage", label: t("sign_language") },
  ];
  useEffect(() => {
    userHandlers.getAllProfile();
  }, []);

  useEffect(() => {
    const hasTrueNew = data.some((item: any) => item.new === true);
    setCancel(hasTrueNew);
    setSave(!hasTrueNew);
  }, [data]);

  useEffect(() => {
    if (eventId) eventMembersHandlers.allByEventMember(eventId);
  }, [eventId, loading]);
  useEffect(() => {
    setMembersData(allByEventMembers);
  }, [allByEventMembers]);

  useEffect(() => {
    return () => {
      eventMembersHandlers.resetEventMembers();
    };
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    var errorList = [];
    errorList = await data?.filter(
      (x: any) =>
        (document.getElementById(`newRole${x?.id}`) as HTMLInputElement)
          .value == ""
    );
    setError(errorList);
    if (errorList?.length === 0) {
      data.map(async (x: any) => {
        if (x.new) {
          const promises = eventMembersHandlers.createEventMember({
            roles: x.role,
            event: eventId,
            profile: x.id,
          });
          setSelectEmail([]);
          await Promise.all(promises).then((values) => {});
        } else {
          const promises = eventMembersHandlers.updateEventMembers(x.id, {
            roles: [x.role],
          });
          await Promise.all(promises).then((values) => {});
        }
      });

      if (deleteMemberItem && deleteMemberItem?.length > 0) {
        const promises = await deleteMemberItem.map((x: any) => {
          return eventMembersHandlers.removeEventMember(x);
        });
        await Promise.all(promises).then((values) => {});
      }
      setSelectEmail([]);
    }
    // const totaldata = [...data, ...data2];
  };
  useEffect(() => {
    var arr: any = [];
    if (membersData?.length > 0 && profiles?.length > 0) {
      arr = profiles.filter((array_el: any) => {
        return (
          membersData.filter((anotherOne_el: any) => {
            return (
              anotherOne_el.email === array_el.email ||
              profile.email === array_el.email
            );
          })?.length == 0
        );
      });
    } else {
      profiles?.map((x: any) => {
        if (x.email !== profile.email) arr.push(x);
      });
    }

    if (arr && arr?.length > 0) {
      const dataWithValue = arr?.map((item: any) => {
        return {
          value: item.email,
          label: item.email,
        };
      });
      setDataForSelect(dataWithValue);
    }
  }, [membersData, profiles, cancel]);

  useEffect(() => {
    const apiData: any = [];
    if (membersData?.length > 0) {
      membersData.map((member: any) =>
        apiData.push({
          email: member.email,
          role: member.roles[0],
          id: member.profileId,
          new: false,
        })
      );
      setData(apiData);
    }
  }, [membersData]);
  const getSelectEmail = async (e: any) => {
    setSelectEmail(e);

    const newMembers = e
      .filter((x: any) => !data.some((member: any) => member.email === x))
      .map((email: any) => ({
        email: email,
        role: "",
        new: true,
        id: profiles.find((y: any) => y.email == email).id,
      }));
    // const selectEmil = dataForSelect.filter((x: any) =>
    //   e.map((email: any) => x.value !== email)
    // );
    const updatedData = [...data, ...newMembers];

    setData(updatedData);
  };

  const getSelectRole = async (e: any, i: any) => {
    const updatedData = [...data];
    setChange(true);
    const dataIndex = updatedData.find((item, index) => index === i);
    dataIndex.role = e;
    setData(updatedData);
  };
  const deleteProfile = async (e: any) => {
    setChange(true);
    const allData = data.filter((x: any) => x.email !== e);
    const deleteFindData = data.find((x: any) => x.email == e);
    if (deleteFindData.new) {
      var arr = [];
      arr = await data.filter((x: any) => {
        return x.email != e;
      });
      arr = arr.map((y: any) => y.email);
      setSelectEmail(arr);
    } else {
      setDeleteMemberItem([...deleteMemberItem, deleteFindData.id]);
    }
    setData(allData);
  };

  const editCancel = () => {
    const _data = data.filter((x: any) => x.new !== true);
    setCancel(false);
    setChange(true);
    setSelectEmail([]);
    setData(_data);
  };

  if (loading) {
    return (
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading height={200} />
      </Card>
    );
  }

  return (
    <Card
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <MultiSelect
        data={dataForSelect}
        label={t("members.select_member")}
        searchable
        mb="xs"
        clearable
        clearButtonLabel={t("members.clear")}
        value={selectEmail}
        onChange={(e: any) => getSelectEmail(e)}
        placeholder={t("members.select_member")}
      />
      {data?.map((x: any, i: number) => (
        <div key={i} style={{ width: "100%", padding: 10 }}>
          <Group grow className={classes.group}>
            <Group className={classes.title}>
              <Mail />
              <Text className={classes.email}>{x?.email}</Text>
            </Group>
            <Group
              className={classes.title}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Group className={classes.select_group}>
                <Select
                  className={classes.select}
                  style={{ width: "80%" }}
                  placeholder={t("members.select_role")}
                  id={`newRole${x?.id}`}
                  value={x?.role}
                  onChange={(e: any) => getSelectRole(e, i)}
                  data={roles}
                />

                {error.map((y: any, i: number) => {
                  return (
                    y.email == x.email && (
                      <div key={i}>
                        <Text color="red">
                          {t("members.please_select_role")}
                        </Text>
                      </div>
                    )
                  );
                })}
              </Group>
              <ActionIcon onClick={(e: any) => deleteProfile(x.email)}>
                <X color="red" />
              </ActionIcon>
            </Group>
          </Group>
        </div>
      ))}
      <Group position="apart" mt="xs">
        <Button
          disabled={!cancel}
          loading={loading}
          color={"red"}
          onClick={editCancel}
        >
          {t("cancel")}
        </Button>
        <Button
          disabled={save && !change}
          loading={loading}
          color={"green"}
          onClick={handleSubmit}
        >
          {t("save")}
        </Button>
      </Group>
    </Card>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.white[1],
    width: "100%",
  },
  select_group: {
    width: "85%",
    gap: 0,
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  group: {
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
  },
  title: {
    "@media (max-width: 768px)": {
      flexDirection: "row",
      maxWidth: "100%",
      width: "100%",
    },
  },
  email: {
    "@media (max-width: 768px)": {
      whiteSpace: "nowrap",
      width: "70%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  select: {
    "@media (max-width: 768px)": {
      width: "100% !important",
    },
  },
}));
export default EditEventPage;
