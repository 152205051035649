export type ReplayLine = {
    value: string;
    meta: {
        writeStart: number;
        writeEnd: number;
    };
}
export interface IReplayClient {
    connect(
        socketUrl: string,
        accessToken: string,
        roomId: string,
        startTime: number,
        language: string,
        onData: (lines: ReplayLine[]) => void,
        onDisconnect: (ev: CloseEvent) => void,
        onConnectionLost: () => void): void;

    disconnect(): void;
}
export class ReplayClient implements IReplayClient {
    private _ws?: WebSocket;

    public connect(
        socketUrl: string,
        accessToken: string,
        roomId: string,
        startTime: number,
        language: string,
        onData: (lines: ReplayLine[]) => void,
        onDisconnect: (ev: CloseEvent, willReconnect: boolean) => void,
        onConnectionLost: () => void): void {
        if (this._ws) {
            throw new Error('ReplayClient already connected');
        }

        this._ws = new WebSocket(socketUrl);
        this._ws.addEventListener('open', () => this._ws!.send(JSON.stringify({ accessToken, roomId })));

        this._ws.addEventListener('message', (message: MessageEvent<any>) => {
            if (message.data === 'authenticated') {
                this._ws!.send(JSON.stringify({ startTime, language, subtitleSegmentation: true }));
            }
            else {
                onData(JSON.parse(message.data));
            }
        });

        this._ws.addEventListener('close', (ev) => {
            delete this._ws;

            if ([3000, 1000, 1002, 1005].indexOf(ev.code) === -1) {
                onConnectionLost();
            }

            onDisconnect(ev as CloseEvent, false);
        });
    }

    public disconnect(): void {
        this._ws?.close();
        delete this._ws;
    }
}